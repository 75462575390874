import axios from "axios";
import { google, ics, office365 } from "calendar-link";
import moment from "moment-timezone/moment-timezone";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaApple, FaGoogle, FaMicrosoft } from "react-icons/fa";
import {
  MdDescription,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdLocationOn,
  MdPerson,
} from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/cross.svg";
import { ReactComponent as QuestionIcon } from "../assets/icons/question.svg";
import { ReactComponent as TildeIcon } from "../assets/icons/tilde.svg";
import colors from "../colors";
import config from "../config";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const ResponsePage = (props) => {
  const [event, setEvent] = useState({});
  const [showGuestList, setShowGuestList] = useState(false);
  const [response, setResponse] = useState({}); // used to store the response per guest per date
  const [all, setAll] = useState(false);
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNUmber] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error has occurred.");
  const [eventPostState, setEventPostState] = useState(false);
  const [icsLink, setIcsLink] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [outlookLink, setOutlookLink] = useState("");
  const [displayCalendarLinks, setDisplayCalendarLinks] = useState(false);
  const [displayAddLinks, setDisplayAddLinks] = useState(false);

  const parsed = queryString.parse(props.location.search);
  const eventId = parsed.eventId;

  useEffect(() => {
    getEvent();

    setName(
      localStorage.getItem("name", name)
        ? localStorage.getItem("name", name)
        : null
    );

    setPhoneNUmber(
      localStorage.getItem("phoneNumber", phoneNumber)
        ? localStorage.getItem("phoneNumber", phoneNumber)
        : null
    );
  }, []);

  useEffect(() => {
    // for each date the guest must have filled in their response
    let allCheck = false;
    event?.dates &&
      event.dates.forEach((date) => {
        response[date._id] !== undefined
          ? (allCheck = true)
          : (allCheck = false);
      });

    setAll(allCheck);
  }, [response]);

  const size = useWindowSize();

  const getEvent = () => {
    axios
      .get(`${config.serverAddress}/events/event/${eventId}`)
      .then((response) => {
        let event = response.data.event;
        setEvent(event);

        let guestsArray = [];

        event.guests.forEach((guest) => {
          guestsArray.push(guest.name);
        });

        const calendarEvent = {
          title: event.title,
          description: `${
            event.description
          }\n\nParticipants:\n${guestsArray.map(
            (guest) => guest.replace(",", "") + "\n"
          )}\n\nGo to https://letscalendar.app/invite?eventId=${eventId} to change your attendance.\n\nOr download Let's Calendar in the store:\nhttps://letscalendar.app`,
          start: event.dates[0].start,
          end: event.dates[0].end,
          location: event.location,
          url: event.url,
        };
        setIcsLink(ics(calendarEvent)); // standard ICS file based on https://icalendar.org
        setGoogleLink(google(calendarEvent)); // https://calendar.google.com/calendar/render...
        // setOutlookLink(outlook(calendarEvent)); // https://outlook.live.com/owa/...
        setOutlookLink(office365(calendarEvent)); // https://outlook.office.com/owa/...
      })
      .catch(function (error) {
        console.warn(error);
      });
  };

  const checkCalendarBtn = () => (
    <div
      onClick={() => setDisplayCalendarLinks(true)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: 22,
        padding: 5,
        borderRadius: 3,
        fontSize: 12,
        color: "#FFF",
        background: colors.secondary,
      }}
    >
      Check your calendar {">"}
    </div>
  );

  const renderDateRow = (index, attendanceButtons, showCheckCalendarBtn) => {
    const renderSmallParticipateBtns = () => (
      <div
        style={{
          marginRight: 20,
          display: "flex",
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        <div
          style={{ marginRight: 20 }}
          onClick={() =>
            setResponse({ ...response, [event.dates[index]._id]: 2 })
          }
        >
          <CheckIcon
            fill={
              response[event.dates[index]._id] === 2 ? "#1BD8CE" : "#C3C3C3"
            }
            opacity={response[event.dates[index]._id] === 2 ? 1 : 0.4}
          />
        </div>
        <div
          style={{ marginRight: 20 }}
          onClick={() =>
            setResponse({ ...response, [event.dates[index]._id]: 1 })
          }
        >
          <TildeIcon
            fill={
              response[event.dates[index]._id] === 1 ? "#FED25B" : "#C3C3C3"
            }
            opacity={response[event.dates[index]._id] === 1 ? 1 : 0.4}
          />
        </div>
        <div
          onClick={() =>
            setResponse({ ...response, [event.dates[index]._id]: 0 })
          }
        >
          <CrossIcon
            fill={
              response[event.dates[index]._id] === 0 ? "#DB4A4A" : "#C3C3C3"
            }
            opacity={response[event.dates[index]._id] === 0 ? 1 : 0.4}
          />
        </div>
      </div>
    );

    let start = moment(event.dates[index].start);
    let end = moment(event.dates[index].end);

    return (
      <div
        key={index}
        style={{ marginBottom: 5, display: "flex", width: "100%" }}
      >
        {/* Check if startdate is the same as enddate */}
        {moment(event.dates[index].start).isBefore(
          moment(event.dates[index].end),
          "day"
        ) ? (
          // different start and end dates (one option)
          <React.Fragment>
            <div style={{ width: "50%", textAlign: "left" }}>
              {start.format("dddd[, ]MMM[ ]DD")}
              <div style={{ fontSize: 12 }}>{start.format("HH[:]mm")}</div>
            </div>

            {!attendanceButtons && (
              <React.Fragment>
                <span style={{ marginRight: 10 }}>-</span>
                <span style={{ flex: 1 }}>
                  {end.format("dddd, MMM DD")}
                  <div style={{ fontSize: 12 }}>{end.format("HH[:]mm")}</div>
                </span>
              </React.Fragment>
            )}

            {attendanceButtons && renderSmallParticipateBtns()}
          </React.Fragment>
        ) : (
          // same start and end dates (one option)
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ marginRight: 10 }}>
                {start.format("dddd[, ]MMM[ ]DD")}
                <div style={{ fontSize: 12 }}>
                  {start.format("HH[:]mm")} - {end.format("HH[:]mm")}
                </div>
              </div>
              {showCheckCalendarBtn && !all && checkCalendarBtn()}
              {showCheckCalendarBtn && all && renderSmallParticipateBtns()}
              {attendanceButtons && renderSmallParticipateBtns()}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="alert alert-danger text-center">
        <h6>Something went wrong...</h6>
        <p>{errorMessage}</p>
      </div>
    );
  };

  const renderConfirmDialog = (disabled) => {
    if (all)
      return (
        <div style={{ width: "100%", marginTop: 20 }}>
          <h6>Let {event.organizerName} know who you are.</h6>
          <p
            className="new-line"
            style={{ color: "#00536F", fontSize: 14, opacity: 0.5 }}
          >
            {!name || !phoneNumber
              ? `We need your name and phone number to let the organizer know when you're available.`
              : `We already filled out your details for you.\nPlease check them.`}
          </p>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <React.Fragment>
              <div id="form" style={{ margin: "10px 0" }}>
                <input
                  type="text"
                  required
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  text="Name"
                  placeholder="Your name"
                ></input>
              </div>
              <div style={{ margin: "10px 0" }}>
                <PhoneInput
                  inputProps={{
                    required: true,
                  }}
                  preferredCountries={["nl", "us"]}
                  countryCodeEditable={false}
                  copyNumbersOnly={false}
                  alwaysDefaultMask={true}
                  inputStyle={{ width: "100%" }}
                  inputClass={"form-control"}
                  country={"nl"}
                  value={phoneNumber}
                  onChange={(phoneNumber) => setPhoneNUmber("+" + phoneNumber)}
                />
              </div>
              {/* <div style={{ margin: "10px 0" }}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  text="Message"
                  placeholder="Optional message"
                ></input>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#12C4B7",
                    width: "100%",
                  }}
                  className="btn btn-lets text-white"
                  type="submit"
                >
                  Send availability
                </button>
              </div>
            </React.Fragment>
          </form>
        </div>
      );
    else if (disabled) {
      return (
        <button
          style={{
            backgroundColor: "#12C4B7",
            width: "100%",
          }}
          className="btn btn-lets text-white disabled"
        >
          Send availability
        </button>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!phoneNumber) return;

    localStorage.setItem("name", name);
    localStorage.setItem("phoneNumber", phoneNumber);

    const body = {
      eventId,
      name,
      phoneNumber,
      response,
      note: message,
    };
    axios
      .post(`${config.serverAddress}/events/participate`, body)
      .then((response) => {
        if (response.data.success === true) setEventPostState(true);
        else {
          setError(true);
          setErrorMessage(response.data.response);
        }
      });
  };

  const renderGuests = () => {
    const findResponse = (guest) => {
      // find all the responses belonging to guest
      let guestResponses = event.dates[0].responses.filter((response) => {
        return response.phoneNumber === guest.phoneNumber;
      });

      // find latest response of guest
      guestResponses.sort(function (a, b) {
        var keyA = new Date(a.dateResponded),
          keyB = new Date(b.dateResponded);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      let attendance = guestResponses[0]?.attendance;

      switch (attendance) {
        case 2:
          return <CheckIcon fill={colors.primary} />;
        case 1:
          return <TildeIcon width={13} height={6} fill={"#FED25B"} />;
        case 0:
          return <CrossIcon fill={"#DB4A4A"} />;
        default:
          return <QuestionIcon fill={"#92A8AF"} />;
      }
    };

    return (
      <div>
        <p
          onClick={() => {
            setShowGuestList(!showGuestList);
          }}
          style={{ marginBottom: 5 }}
        >
          {event.guests.length === 1 ? (
            <React.Fragment>1 guest</React.Fragment>
          ) : (
            <React.Fragment>{event.guests.length} people</React.Fragment>
          )}{" "}
          {showGuestList ? (
            <MdKeyboardArrowLeft size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </p>
        {showGuestList && (
          <div>
            {event.guests.map((guest, index) => (
              <div key={index} style={{ fontSize: 12, fontWeight: 300 }}>
                {findResponse(guest)}
                <span style={{ marginLeft: 5 }}>{guest.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  if (!event) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Event not found... it seems the link is broken.</h1>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {event?.organizerName
            ? event.organizerName +
              " invited you to " +
              event?.title +
              " | Let's Calendar invitation"
            : "Let's Calendar invitation"}
        </title>
        <description>
          You have been invited to a Let's event. Please provide your
          availability.
        </description>
        <meta property="og:url" content="https://letscalendar.app/invite" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={
            event?.organizerName
              ? event.organizerName +
                " invited you to " +
                event?.title +
                " | Let's Calendar invitation"
              : "Let's Calendar invitation"
          }
        />
        <meta
          property="og:description"
          content="You have been invited to a Let's event. Please provide your
          availability."
        />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flex: 1,
          justifyContent: "center",
          position: "relative",
          height: size.height,
        }}
      >
        {(displayCalendarLinks || displayAddLinks) && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backdropFilter: "blur(49px)",
              WebkitBackdropFilter: "blur(49px)",
            }}
            onClick={() => {
              setDisplayCalendarLinks(false);
              setDisplayAddLinks(false);
            }}
          >
            <div
              style={{
                textAlign: "center",
                flexDirection: "column",
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ marginBottom: 20, fontSize: 27 }}>
                Choose your calendar
              </h4>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: 170,
                }}
              >
                <div
                  style={{
                    borderRadius: 3,
                    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.36)",
                    backgroundColor: "#FFF",
                    width: 80,
                    height: 80,
                    margin: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      cursor: "pointer",
                      color: colors.secondary,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      if (displayCalendarLinks) {
                        window.open("calshow:", null, null);
                        setDisplayCalendarLinks(false);
                      } else if (displayAddLinks) {
                        window.open(icsLink, null, null);
                        setDisplayAddLinks(false);
                      }
                    }}
                  >
                    <FaApple size={40} />
                    <div>Apple</div>
                  </a>
                </div>
                <div
                  style={{
                    borderRadius: 3,
                    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.36)",
                    backgroundColor: "#FFF",
                    width: 80,
                    height: 80,
                    margin: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      cursor: "pointer",
                      color: colors.secondary,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      if (displayCalendarLinks) {
                        window.open(
                          "https://calendar.google.com/calendar/u/0/r",
                          null,
                          null
                        );
                        setDisplayCalendarLinks(false);
                      } else if (displayAddLinks) {
                        window.open(googleLink, null, null);
                        setDisplayAddLinks(false);
                      }
                    }}
                  >
                    <FaGoogle size={40} /> <div>Google</div>
                  </a>
                </div>
                <div
                  style={{
                    borderRadius: 3,
                    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.36)",
                    backgroundColor: "#FFF",
                    width: 80,
                    height: 80,
                    margin: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      cursor: "pointer",
                      color: colors.secondary,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      if (displayCalendarLinks) {
                        window.open("ms-outlook://calendar/events", null, null);
                        setDisplayCalendarLinks(false);
                      } else if (displayAddLinks) {
                        window.open(outlookLink, null, null);
                        setDisplayAddLinks(false);
                      }
                    }}
                  >
                    <FaMicrosoft size={40} /> <div>Microsoft</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            maxWidth: 450,
            padding: 15,
          }}
        >
          <a href="https://letscalendar.app">
            <img
              src={require("../assets/logo-lets.png")}
              alt="Logo Let's"
              style={{ width: 140, marginTop: 41 }}
            />
          </a>

          {!eventPostState && (
            // first screen
            <div style={{ width: "100%" }}>
              <h1
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  fontWeight: 400,
                }}
              >
                {event.organizerName} invited you to join <br />
                an event!
              </h1>
              <div
                style={{
                  marginTop: 40,
                  flex: 1,
                  width: "100%",
                }}
              >
                <ul>
                  {/* event title */}
                  <li className="primary-info">
                    <span className="iconWrapper">
                      <img
                        src={require("../assets/icons/burst.svg")}
                        alt="Burst icon"
                      />
                    </span>

                    {event.title}
                  </li>

                  {/* event date picker notice */}
                  {event?.dates?.length > 1 && (
                    <li
                      className="secundary-info"
                      style={{
                        marginLeft: 40,
                        fontSize: 12,
                      }}
                    >
                      The organizer is trying to decide when to do the event.
                      Please help by indicating when you’re available
                    </li>
                  )}

                  {/* event date */}
                  <li
                    className="primary-info"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <span className="iconWrapper">
                      <img
                        src={require("../assets/icons/calendar.svg")}
                        alt="Calendar icon"
                        style={{ marginTop: 5 }}
                      />
                    </span>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {event.dates?.length > 1 && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>Mulitple dates </p>
                          {!all && checkCalendarBtn()}
                        </div>
                      )}

                      {event.dates?.length === 1 ? (
                        <React.Fragment>
                          {renderDateRow(0, false, true)}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {event?.dates?.map((date, index) =>
                            renderDateRow(index, true)
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </li>

                  {event.guests && !all && (
                    <li style={{ display: "flex" }}>
                      <span className="iconWrapper">
                        <MdPerson size={"1.5rem"} color={colors.primary} />
                      </span>

                      <div className="secundary-info">{renderGuests()}</div>
                    </li>
                  )}

                  {event.location && !all && (
                    <li style={{ display: "flex" }}>
                      <span className="iconWrapper">
                        <MdLocationOn size={"1.5rem"} color={colors.primary} />
                      </span>

                      <div className="secundary-info">{event.location}</div>
                    </li>
                  )}

                  {event.description && !all && (
                    <li style={{ display: "flex" }}>
                      <span className="iconWrapper">
                        <MdDescription size={"1.5rem"} color={colors.primary} />
                      </span>

                      <div className="secundary-info">{event.description}</div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}

          {eventPostState && (
            //  /second screen
            // third screen: accepted invitation
            <div>
              <h1 style={{ textAlign: "center", marginTop: 10 }}>
                {response[event.dates[0]._id] === 2 ? (
                  <React.Fragment>
                    {event.organizerName} is excited that you are joining this
                    event.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Thank you for responding,
                    <br /> maybe next time.
                  </React.Fragment>
                )}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  flex: 1,
                  color: "#00536F",
                }}
              >
                <img
                  src={require("../assets/success-gif.gif")}
                  alt={"Success GIF"}
                  style={{
                    marginTop: 20,
                    marginBottom: 40,
                    borderRadius: 250,
                    width: 250,
                    height: 250,
                  }}
                />

                {event?.dates?.length === 1 && (
                  <React.Fragment>
                    <div
                      onClick={() => {
                        setDisplayAddLinks(true);
                      }}
                      style={{
                        fontSize: 14,
                        backgroundColor: "#007E93",
                        width: "100%",
                      }}
                      className="btn btn-lets text-white"
                    >
                      Add to calendar
                    </div>
                    <p style={{ marginTop: 20 }}>Or</p>
                  </React.Fragment>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/lets-calendar/id1523723259"
                  target="_blank"
                >
                  <img
                    src={require("../assets/icons/apple-store.png")}
                    alt="Apple Store logo"
                    style={{
                      margin: "0 20px",
                      resizeMode: "contain",
                      width: 30,
                      aspectRatio: 1,
                    }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.letscalendar.calendar"
                  target="_blank"
                >
                  <img
                    src={require("../assets/icons/google-store.png")}
                    alt="Google Play Store logo"
                    style={{
                      margin: "0 20px",
                      resizeMode: "contain",
                      width: 30,
                      aspectRatio: 1,
                    }}
                  />
                </a>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    width: "80%",
                    color: "#00536F",
                    fontSize: 14,
                  }}
                >
                  Download the Let’s app to see who’s going, get updates and
                  plan your next event!
                </div>
              </div>
            </div>
          )}

          {/* button row */}
          {event?.dates?.length === 1 && !all && !eventPostState && (
            <div
              style={{
                width: "100%",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <a
                  className="btn btn-lets text-white"
                  onClick={() =>
                    setResponse({ ...response, [event.dates[0]._id]: 2 })
                  }
                  style={{
                    flex: 1,
                    width: "100%",
                    backgroundColor: "#12C4B7",
                  }}
                >
                  Going
                </a>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  color: "#00536F",
                  textAlign: "center",
                }}
              >
                <a
                  className="btn btn-lets btn-outline-warning"
                  onClick={() =>
                    setResponse({ ...response, [event.dates[0]._id]: 1 })
                  }
                  style={{
                    flex: 1,
                    borderWidth: 2,
                    marginRight: "1rem",
                  }}
                >
                  Maybe
                </a>
                <a
                  className="btn btn-lets btn-outline-danger"
                  onClick={() =>
                    setResponse({ ...response, [event.dates[0]._id]: 0 })
                  }
                  style={{
                    flex: 1,
                    borderWidth: 2,
                    marginLeft: "1rem",
                  }}
                >
                  Not going
                </a>
              </div>
            </div>
            // /button row
          )}
          {event.dates?.length === 1 && !eventPostState && error === false
            ? renderConfirmDialog()
            : error === true && renderErrorMessage()}
          {event.dates?.length > 1 &&
            !eventPostState &&
            renderConfirmDialog(true)}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResponsePage;
