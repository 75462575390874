import React from "react";
import { Helmet } from "react-helmet";
import colors from "../colors";
import { Container, Row, Col } from "react-bootstrap";

const HomePage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Let's Calendar</title>
        <description>
          Let’s Calendar is the easiest way to create and share social events.
          We believe creating an event should be as easy as possible to enable
          you to have more meaningful moments together.
        </description>
      </Helmet>
      <Container
        fluid
        style={{
          backgroundColor: colors.lightish,
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Container>
          <Row>
            <Col>
              <img
                className="lets-logo"
                src={require("../assets/logo-lets.png")}
                alt="Let's logo"
              />
            </Col>
          </Row>
          <wrapper>
            <Row>
              <Col xs={12} sm={6}>
                <h1 className="title">Spend time together</h1>
                <p className="header-text">
                  Let’s Calendar is the easiest way to create and share social
                  events. We believe creating an event should be as easy as
                  possible to enable you to have more meaningful moments
                  together.
                </p>

                <div
                  className="d-md-none"
                  style={{
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    paddingTop: 20,
                    zIndex: 5,
                    backgroundColor: "#FFF",
                    width: "100%",
                  }}
                >
                  <h3 className="subtitle">Download the app</h3>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <a
                      href="https://apps.apple.com/us/app/lets-calendar/id1523723259"
                      target="_blank"
                      style={{
                        textAlign: "center",
                        margin: "0 1rem",
                      }}
                    >
                      <img
                        src={require("../assets/icons/apple-store.png")}
                        alt="Apple Store logo"
                        style={{
                          resizeMode: "contain",
                          width: 30,
                          aspectRatio: 1,
                        }}
                      />
                      <p className="header-text">App Store</p>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=app.letscalendar.calendar"
                      target="_blank"
                      style={{
                        textAlign: "center",
                        margin: "0 1rem",
                      }}
                    >
                      <img
                        src={require("../assets/icons/google-store.png")}
                        alt="Google Play Store logo"
                        style={{
                          resizeMode: "contain",
                          width: 30,
                          aspectRatio: 1,
                        }}
                      />
                      <p className="header-text">Play Store</p>
                    </a>
                  </div>
                </div>

                <div
                  className="d-none d-md-block"
                  style={{ marginTop: "4rem" }}
                >
                  <h3 className="subtitle">Download the app</h3>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <a
                      href="https://apps.apple.com/us/app/lets-calendar/id1523723259"
                      target="_blank"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={require("../assets/icons/apple-store.png")}
                        alt="Apple Store logo"
                        style={{
                          resizeMode: "contain",
                          width: 50,
                          aspectRatio: 1,
                        }}
                      />
                      <p className="header-text">App Store</p>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=app.letscalendar.calendar"
                      target="_blank"
                      style={{
                        margin: "0 50px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={require("../assets/icons/google-store.png")}
                        alt="Google Play Store logo"
                        style={{
                          resizeMode: "contain",
                          width: 50,
                          aspectRatio: 1,
                        }}
                      />
                      <p className="header-text">Play Store</p>
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <img
                  className="app-screen"
                  src={require("../assets/screen.png")}
                  alt="App screen"
                  style={{
                    resizeMode: "contain",
                    maxWidth: 300,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <div style={{ display: "flex" }}>
                  <img
                    src={require("../assets/icons/calendar.png")}
                    alt="Calendar icon"
                    className="iconWrapperSmall"
                  />
                  <p className="header-text">
                    Invite friends, they don’t need to have the app
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <img
                    src={require("../assets/icons/time.png")}
                    alt="Time icon"
                    className="iconWrapperSmall"
                  />
                  <p className="header-text">
                    Decide together on the best date
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <img
                    src={require("../assets/icons/happy.png")}
                    alt="Happy icon"
                    className="iconWrapperSmall"
                  />
                  <p className="header-text">Spend more time enjoying life</p>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
              <Col xs={12} md={3}>
                <h5 className="feature-title">Create</h5>
                <img
                  src={require("../assets/create.png")}
                  alt="Create screenshot"
                  style={{ width: "97%", resizeMode: "contain" }}
                />
              </Col>
              <Col xs={12} md={3}>
                <h5 className="feature-title">Invite</h5>
                <img
                  src={require("../assets/invite.png")}
                  alt="Invite screenshot"
                  style={{ width: "97%", resizeMode: "contain" }}
                />
              </Col>
              <Col xs={12} md={3}>
                <h5 className="feature-title">Pick a date</h5>
                <img
                  src={require("../assets/date.png")}
                  alt="Pick a date screenshot"
                  style={{ width: "97%", resizeMode: "contain" }}
                />
              </Col>
              <Col xs={12} md={3}>
                <h5 className="feature-title">Chat</h5>
                <img
                  src={require("../assets/chat.png")}
                  alt="Chat screenshot"
                  style={{ width: "97%", resizeMode: "contain" }}
                />
              </Col>
            </Row>
          </wrapper>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default HomePage;
